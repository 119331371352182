import { Stack, SimpleGrid } from "@chakra-ui/react";
import { ShadersDemo, GenerativeArtDemo, JSXBeatsDemo, RemotionDemo } from "demos";
import { LazyLoader } from "../../../../src/components/compounds/LazyLoader.tsx";
import * as React from 'react';
export default {
  Stack,
  SimpleGrid,
  ShadersDemo,
  GenerativeArtDemo,
  JSXBeatsDemo,
  RemotionDemo,
  LazyLoader,
  React
};