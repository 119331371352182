import { Box, HStack, Icon } from '@chakra-ui/react'
import React, { PropsWithChildren, ReactNode, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { MotionBox } from './MotionBox'

function Collapsable({header , children}: PropsWithChildren<{header: ReactNode}>) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Box as="details" isolation="isolate" open={isOpen} cursor="pointer">
            <HStack
                as="summary"
                align="center"
                my={4}
                zIndex={5}
                onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                }}
            >
                <MotionBox
                    display="inline-flex"
                    animate={{
                        rotate: isOpen ? 90 : 0,
                    }}
                >
                    <Icon as={FaChevronRight} />
                </MotionBox>
                {header}
            </HStack>

            <MotionBox
                animate={{
                    opacity: isOpen ? 1 : 0,
                    y: isOpen ? 0 : -20,
                }}
            >
                {children}
            </MotionBox>
        </Box>
    )
}

export { Collapsable }