import React from "react"
import loadable from "@loadable/component"
import { LazyLoader } from "../components/compounds/LazyLoader"

const ShadersDemo = () => (
    <LazyLoader Child={loadable(() => import("./ShadersDemo"))} />
)
const JSXBeatsDemo = () => (
    <LazyLoader Child={loadable(() => import("./JSXBeatsDemo"))} />
)
const RemotionDemo = () => (
    <LazyLoader Child={loadable(() => import("./RemotionDemo"))} />
)
const GenerativeArtDemo = () => (
    <LazyLoader Child={loadable(() => import("./GenerativeArtDemo"))} />
)
const AlgebraicGraphDemo = () => (
    <LazyLoader Child={loadable(() => import("./AlgebraicGraphDemo"))} />
)
const ColorWheelDemo = () => (
    <LazyLoader Child={loadable(() => import("./ColorWheelDemo"))} />
)
const StateMachineDemo = () => (
    <LazyLoader Child={loadable(() => import("./StateMachinesDemo"))} />
)
const MapDemo = () => <LazyLoader Child={loadable(() => import("./MapDemo"))} />
const NumberSliderDemo = () => (
    <LazyLoader Child={loadable(() => import("./NumberSliderDemo"))} />
)

export {
    ShadersDemo,
    GenerativeArtDemo,
    JSXBeatsDemo,
    RemotionDemo,
    AlgebraicGraphDemo,
    ColorWheelDemo,
    StateMachineDemo,
    MapDemo,
    NumberSliderDemo,
}
