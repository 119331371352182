import Equation from "../../../../src/content/blog/future-of-software-design-and-development/assets/equation.svg";
import PeriodicsTable from "../../../../src/content/blog/future-of-software-design-and-development/assets/periodics-table.svg";
import { AlgebraicGraphDemo, ColorWheelDemo, MapDemo, NumberSliderDemo, StateMachineDemo } from 'demos';
import * as React from 'react';
export default {
  Equation,
  PeriodicsTable,
  AlgebraicGraphDemo,
  ColorWheelDemo,
  MapDemo,
  NumberSliderDemo,
  StateMachineDemo,
  React
};