import {
    Box, IconButton, useColorMode,
    usePrefersReducedMotion
} from "@chakra-ui/react"
import { AnimatePresence } from "framer-motion"
import React from "react"
import { IoMdMoon, IoMdSunny } from "react-icons/io"
import useSound from "use-sound"
import dongSound from "../../../assets/sounds/dong.wav"
import { MotionBox } from "../MotionBox"

function ColorModeToggle() {
    const { toggleColorMode, colorMode } = useColorMode()

    const prefersReducedMotion = usePrefersReducedMotion()

    const [playSound] = useSound(dongSound, {
        interrupt: true,
        playbackRate: colorMode === "light" ? 0.8 : 0.5,
    })

    return (
        <MotionBox
            initial={{
                scale: 1,
                "--opacity": 0.8,
                rotate: 0,
            }}
            whileHover={{
                scale: prefersReducedMotion ? 1 : 1.2,
                "--opacity": 1,
                rotate: prefersReducedMotion ? 0 : 20,
                transition: {
                    type: "spring",
                    mass: 10,
                    damping: 1,
                },
            }}
            transition={{
                type: "spring",
                mass: 6,
                damping: 4,
            }}
        >
            <IconButton
                aria-label="color mode toggle"
                onClick={() => {
                    toggleColorMode()
                    playSound()
                }}
                size="lg"
                variant="unstyled"
                cursor="pointer"
                opacity="var(--opacity, 1)"
                color={colorMode === "light" ? "orange.300" : "inherit"}
                _focus={{
                    boxShadow: 'none'
                }}
                _focusVisible={{
                    transform: 'scale(1.2)',
                    color: colorMode === "light" ? "yellow.400" : "yellow.200",
                    opacity: 1,
                }}
                _hover={{
                    color: colorMode === "light" ? "yellow.400" : "yellow.200",
                }}
                icon={
                    <Box
                        transitionDuration="slower"
                        transitionProperty="common"
                        filter="url(#filter-shadow-sm)"
                        p={4}
                    >
                        <AnimatePresence exitBeforeEnter>
                            {colorMode === "light" ? (
                                <IconWrapper key="color-mode-sun">
                                    <IoMdSunny />
                                </IconWrapper>
                            ) : (
                                <IconWrapper key="color-mode-moon">
                                    <IoMdMoon />
                                </IconWrapper>
                            )}
                        </AnimatePresence>
                    </Box>
                }
            />
        </MotionBox>
    )
}

function IconWrapper({children}) {
    const prefersReducedMotion = usePrefersReducedMotion()

    const hiddenTransition = {
        scale: prefersReducedMotion ? 1 : 0,
        opacity: 0,
    }

    const visibleTransition = { scale: 1, opacity: 1}

    return (
        <MotionBox
            initial={hiddenTransition}
            animate={visibleTransition}
            exit={hiddenTransition}
        >
            {children}
        </MotionBox>
    )
}

export { ColorModeToggle }

