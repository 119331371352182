import {useBreakpoint} from '@chakra-ui/react'
import React from 'react'

type breakpoints = 'base' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

type ResponsiveProps = {
    hideIn: breakpoints | Array<breakpoints>
}

function Responsive({children, hideIn}: React.PropsWithChildren<ResponsiveProps>) {
    const breakpoint = useBreakpoint("base") as breakpoints
    const shouldHide = hideIn instanceof Array ? hideIn.includes(breakpoint) : hideIn === breakpoint
    return <>{shouldHide ? null : children}</>
}

export { Responsive }