import { Container, Heading, VStack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import BlogPostCard from '../components/compounds/BlogPostCard'
import SEO from '../components/seo'
import { PageTransitionType } from '../components/wrappers/PageTransition'

const Blog = ({ data }) => {
    const blogPosts = data.allMdx.edges.map(({ node }) => node)
    return (
        <>
            <SEO title="Blog" />
            <Container py={12}>
                <VStack align="stretch" spacing={10}>
                    <Heading as="h1">Blog</Heading>
                    <VStack align="stretch" spacing={6}>
                        {blogPosts.map(
                            ({
                                frontmatter: {
                                    title,
                                    description,
                                    date,
                                    path,
                                    thumbnail,
                                    thumbnailCredit,
                                    videosDurationsInMins,
                                },
                                timeToRead,
                            }) => (
                                <BlogPostCard
                                    key={path}
                                    title={title}
                                    description={description}
                                    path={path}
                                    date={date}
                                    timeToRead={timeToRead}
                                    videosDurationsInMins={
                                        videosDurationsInMins
                                    }
                                    thumbnail={thumbnail}
                                    thumbnailCredit={thumbnailCredit}
                                />
                            )
                        )}
                    </VStack>
                </VStack>
            </Container>
        </>
    )
}

export const blogPageTransition: PageTransitionType = [
    [/blog/, { initial: {}, active: {} }],
    {
        initial: {
            y: "5%",
            opacity: 0,
        },
        active: { y: 0, opacity: 1 },
    },
]

export const _ = graphql`
    query AllBlogPosts {
        allMdx(
            filter: { frontmatter: { draft: { ne: true } } }
            sort: { order: DESC, fields: frontmatter___date }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        description
                        path
                        date
                        thumbnail {
                            name
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    width: 640
                                    placeholder: TRACED_SVG
                                    tracedSVGOptions: {
                                        color: "#2c5282"
                                        background: "#bee3f8"
                                    }
                                )
                            }
                            extension
                            publicURL
                        }
                        thumbnailCredit
                        videosDurationsInMins
                    }
                    timeToRead
                }
            }
        }
    }
`

export default Blog