import { Box, Skeleton, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { useInView } from 'react-intersection-observer'

type LazyLoaderProps = {
    Child: React.ComponentType
}

function LazyLoader({ Child }: LazyLoaderProps) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "350px 0px",
    })
    
    
    return (
        <Box ref={ref} w="full">
            {inView ? (
                <Child />
            ) : (
                <Skeleton
                    w="full"
                    h="xs"
                    startColor={useColorModeValue("blue.100", "blue.400")}
                    endColor={useColorModeValue("blue.400", "blue.800")}
                />
            )}
        </Box>
    )
}

export {LazyLoader}