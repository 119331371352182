import React from 'react'
import Root from './src/components/wrappers/Root'
import Layout from './src/components/wrappers/layout'

export const wrapRootElement = ({ element }) => {
    return <Root>{element}</Root>
}

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}
