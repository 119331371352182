import React from 'react'
import { Wrap, Icon, useColorMode, WrapItem, Box } from '@chakra-ui/react'
import {
    IoLogoLinkedin,
    IoMdMail,
    IoLogoGithub,
    IoLogoCodepen,
    IoLogoTwitter,
} from 'react-icons/io'

const links = [
    {
        label: "linkedin",
        url: "https://www.linkedin.com/in/hazem-ibrahim-6aa25b136/",
        newTab: true,
        icon: IoLogoLinkedin,
    },
    {
        label: "twitter",
        url: "https://twitter.com/HazemOIbrahim",
        newTab: true,
        icon: IoLogoTwitter,
    },
    {
        label: "email",
        url: "mailto:hazemohashad@gmail.com",
        newTab: false,
        icon: IoMdMail,
    },
    {
        label: "github",
        url: "https://github.com/hazem3500",
        newTab: true,
        icon: IoLogoGithub,
    },
    {
        label: "codepen",
        url: "https://codepen.io/hazem3500",
        newTab: true,
        icon: IoLogoCodepen,
    },
]

export default function SocialLinks() {
    const { colorMode } = useColorMode()
    return (
        // @ts-expect-error
        <Wrap
            fontSize={["5xl", null, "4xl"]}
            lineHeight={0}
            spacing={{ base: 2, md: 4 }}
            align="center"
            justify="center"
        >
            {links.map(({ url, label, newTab, icon }) => (
                <WrapItem key={label}>
                    <Box
                        as="a"
                        href={url}
                        aria-label={label}
                        target={newTab ? "_blank" : '_self'}
                        rel={newTab ? "noreferrer" : ''}
                        transition="all 250ms ease-out"
                        _hover={{
                            color:
                                colorMode === "light" ? "gray.600" : "gray.400",
                        }}
                        _focus={{
                            color:
                                colorMode === "light" ? "gray.600" : "gray.400",
                            transform: "scale(1.2)",
                        }}
                    >
                        <Icon as={icon} cursor="pointer" />
                    </Box>
                </WrapItem>
            ))}
        </Wrap>
    )
}
