import { Box, HStack, Link, Stack, Text } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'

type BlockQuoteProps = {
    author?: string
    cite?: string
}

function BlockQuote({ cite, author,children }: PropsWithChildren<BlockQuoteProps>) {
    return (
        <Stack
            as="figure"
            borderInlineStartColor="blue.400"
            borderInlineStartStyle="solid"
            borderInlineStartWidth={4}
            bgColor="blue.100"
            p={[6, null, 8]}
            paddingInlineStart={[8, null, 10]}
            borderEndRadius="md"
            spacing={2}
            _dark={{
                bgColor: "blue.900",
                borderInlineStartColor: "blue.600",
            }}
        >
            <Box
                as="blockquote"
                cite={cite}
                fontStyle="italic"
                fontWeight="medium"
                sx={{
                    "*": {
                        display: "inline",
                    },
                }}
            >
                <Text
                    as="span"
                    fontSize="2xl"
                    color="blue.400"
                    fontWeight="bold"
                    lineHeight={1}
                    opacity={0.5}
                >
                    “
                </Text>
                {children}
                <Text
                    as="span"
                    fontSize="2xl"
                    color="blue.400"
                    fontWeight="bold"
                    lineHeight={1}
                    opacity={0.5}
                >
                    ”
                </Text>
            </Box>
            {author && (
                <HStack
                    spacing={2}
                    as="figcaption"
                    fontWeight="bold"
                    justify="flex-end"
                    align="center"
                >
                    <span>—{author}</span>
                    {
                        cite && (
                            <Link href={cite}
                            _light={{
                                color: "blue.400",
                                _hover: {
                                    color: "blue.600",
                                },
                                _focusVisible: {
                                    color: "blue.600",
                                }
                            }}
                            _dark={{
                                color: "blue.200",
                                _hover: {
                                    color: "blue.500",
                                },
                                _focusVisible: {
                                    color: "blue.500",
                                }
                            }}
                            isExternal>
                                <FaExternalLinkAlt/>
                            </Link>
                        )
                    }
                </HStack>
            )}
        </Stack>
    )
}

export {BlockQuote}