import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import {
    Heading,
    Text,
    Code,
    Divider,
    UnorderedList,
    OrderedList,
    ListItem,
    Link,
    Image,
    Box,
    Stack,
    SimpleGrid
} from '@chakra-ui/react'
import { HeaderAnchorLink } from '../atoms/HeaderAnchorLink'
import { BlockQuote } from '../atoms/BlockQuote'
import { Collapsable } from '../atoms/Collapsable'
import { Figure } from '../atoms/Figure'
import { Responsive } from '../atoms/Responsive'
import { FullBleed } from './FullBleed'
import { AudioPlayer } from '../atoms/AudioPlayer/AudioPlayer'

const h1 = props => <Heading as="h2" size="xl" {...props} />
const h2 = props => <Heading as="h3" size="lg" {...props} />
const h3 = props => <Heading as="h4" size="md" {...props} />
const p = props => <Text as="p" {...props} />
const strong = props => (
    <Text
        as="strong"
        fontWeight="bold"
        bgGradient="linear(180deg, blue.300, blue.600)"
        bgClip="text"
        sx={{
            'a': {
                bgGradient: 'inherit',
                bgClip: 'text'
            }
        }}
        {...props}
    />
)
const em = props => <Text as="em" fontStyle="italic" {...props} />
const deleteElement = props => <Text as="del" {...props} />
const inlineCode = props => <Code {...props} />
const hr = props => <Divider {...props} />
const ul = props => <UnorderedList listStylePosition="inside" ps={4} {...props} />
const ol = props => <OrderedList listStylePosition="inside" ps={4} {...props} />
const li = props => <ListItem {...props} mb={2} />
const a = props => {
    const isExternal = props.href?.[0] !== "#";
    const isHeaderLink = props.className?.includes("header-link")
    const isChildTextOnly = typeof props.children === "string"
    return isHeaderLink ? (
        <HeaderAnchorLink {...props} />
    ) : (
        <Link isExternal={isExternal} variant={isChildTextOnly ? "underlined" : undefined} sx={{'--text-length': props.children?.length}} {...props} />
    )
}
const aside = props => (
    <Box
        as="aside"
        borderInlineStartColor="blue.400"
        borderInlineStartStyle="solid"
        borderInlineStartWidth={4}
        bgColor="blue.100"
        p={[6, null, 8]}
        paddingInlineStart={[8, null, 10]}
        borderEndRadius="md"
        spacing={2}
        _dark={{
            bgColor: "blue.900",
            borderInlineStartColor: "blue.600",
        }}
        {...props}
    />
)
const image = props => <Image w="full" h="full" {...props} />

const video = props => <Box as="video" preload="metadata" maxH="lg" w="full" maxW="calc(100vw - 2rem)" bg="black" {...props} />
const blockquote = props => (
    <BlockQuote {...props}/>
)

const UnderlineLink = props => <Link isExternal textDecoration="underline" {...props} />

const components = {
    aside,
    h1,
    h2,
    h3,
    p,
    strong,
    em,
    delete: deleteElement,
    inlineCode,
    hr,
    ul,
    ol,
    li,
    a,
    image,
    video,
    blockquote,
    BlockQuote,
    Collapsable,
    Box,
    Stack,
    SimpleGrid,
    Figure,
    FullBleed,
    Link: UnderlineLink,
    Responsive,
    audio: AudioPlayer,
}

export default ({ children }) => (
    <MDXProvider components={components}>
        <Box
            // TODO: SEE HOW TO APPLY STYLES WITHOUT `sx`
            sx={{
                img: {
                    width: 'full',
                    height: 'full'
                }
            }}
        >
            {children}
        </Box>
    </MDXProvider>
)
