import {
    Box,
    Heading,
    HStack,
    Icon,
    Image,
    Skeleton,
    Stack,
    StackDivider,
    Text,
    useColorMode,
    usePrefersReducedMotion,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import React from "react"
import { FaCalendar, FaClock, FaVideo } from "react-icons/fa"
import { MotionBox } from "../atoms/MotionBox"
import { ThumbnailBadge } from "../atoms/ThumbnailBadge"

type BlogPostCardProps = {
    title: string
    description: string
    date: string
    path: string
    timeToRead: number
    videosDurationsInMins: number
    thumbnail: ImageDataLike
    thumbnailCredit: string
}

export default function BlogPostCard({
    title,
    description,
    date,
    path,
    timeToRead,
    videosDurationsInMins,
    thumbnail,
    thumbnailCredit,
}: BlogPostCardProps) {
    const { colorMode } = useColorMode()

    const image = getImage(thumbnail)

    const preferReducedMotion = usePrefersReducedMotion()

    return (
        <Box
            as={Link}
            aria-label={title}
            to={`/blog/${path}`}
            filter="url(#filter-shadow-xl)"
            boxShadow="md"
            borderRadius="md"
            bgColor={colorMode === "light" ? "gray.100" : "gray.800"}
            cursor="pointer"
            transitionProperty="common"
            transitionDuration="normal"
            role="group"
            _hover={{
                boxShadow: "lg",
                transitionTimingFunction: "ease-out",
            }}
            _focusVisible={{
                boxShadow: "lg",
                transitionTimingFunction: "ease-out",
            }}
            _light={{
                borderColor: "gray.200",
            }}
        >
            <Box position="relative" as="figure">
                {thumbnail && (
                    <MotionBox
                        key={`${title}-thumbnail`}
                        layoutId={!preferReducedMotion && `${title}-thumbnail`}
                        h={{ base: "2xs", md: "xs" }}
                        borderTopRadius="md"
                        overflow="hidden"
                        bgColor="blue.700"
                    >
                        {thumbnailCredit && (
                            <ThumbnailBadge id={`${title}-thumbnail-badge`} borderStartEndRadius="md">
                                Illustration by {thumbnailCredit}
                            </ThumbnailBadge>
                        )}
                        <Box
                            h="full"
                            w="full"
                            transitionTimingFunction="ease-in"
                            _groupHover={{
                                transform: "scale(1.1)",
                                transitionTimingFunction: "ease-out",
                            }}
                            _groupFocus={{
                                transform: "scale(1.1)",
                                transitionTimingFunction: "ease-out",
                            }}
                            transitionProperty="transform"
                            transitionDuration="normal"
                            willChange="transform"
                        >
                            {!image ? (
                                <Image
                                    height="full"
                                    width="full"
                                    fallback={<Skeleton boxSize="full" />}
                                    objectFit="cover"
                                    objectPosition="center"
                                    fall
                                    src={thumbnail.publicURL}
                                    alt={thumbnail.name}
                                />
                            ) : (
                                <Box
                                    as={GatsbyImage}
                                    image={image}
                                    alt={thumbnail.name}
                                    style={{ height: "100%", width: "100%" }}
                                    imgStyle={{
                                        objectFit: "cover",
                                        objectPosition: "center",
                                    }}
                                />
                            )}
                        </Box>
                    </MotionBox>
                )}
                <MotionBox
                    as="figcaption"
                    position="absolute"
                    bottom={0}
                    left={0}
                    right={0}
                    px={6}
                    pb={4}
                    pt={8}
                    bgGradient={{
                        base: "linear(to-t, blackAlpha.900, blackAlpha.600 70%, rgba(0,0,0,0))",
                        md: "linear(to-t, blackAlpha.700,  blackAlpha.600, rgba(0,0,0,0))",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        delay: 0.5,
                    }}
                >
                    <Heading
                        as="h2"
                        size="xl"
                        color="white"
                        lineHeight={{ base: 7, md: 10 }}
                        textShadow="0 0 12px var(--chakra-colors-blackAlpha-700)"
                    >
                        {title}
                    </Heading>
                </MotionBox>
            </Box>

            <Stack px={6} py={4} spacing={{ base: "0.7em", md: "0.5em" }}>
                <Text>{description}</Text>
                <Stack
                    mt={2}
                    direction={["column", null, null, "row"]}
                    spacing={[1, null, null, 4]}
                    divider={
                        <StackDivider
                            borderColor={{ lg: "gray.300" }}
                            _dark={{
                                borderColor: {
                                    lg: "gray.600",
                                },
                            }}
                        />
                    }
                >
                    <HStack>
                        <Icon as={FaCalendar} color="gray.600" />
                        <Text as="i"> published on {date}</Text>
                    </HStack>
                    <HStack>
                        <Icon as={FaClock} color="gray.600" />
                        <Text as="i"> {timeToRead} min read</Text>
                    </HStack>
                    {videosDurationsInMins && (
                        <HStack>
                            <Icon as={FaVideo} color="gray.600" />{" "}
                            <Text as="i">
                                {videosDurationsInMins} min videos
                            </Text>
                        </HStack>
                    )}
                </Stack>
            </Stack>
        </Box>
    )
}
