import { Grid } from "@chakra-ui/react"
import { AnimatePresence, AnimateSharedLayout } from "framer-motion"
import React from "react"
import SVGFilters from "../atoms/SVGFilters"
import Header from "../compounds/Header"
import { PageTransition } from "./PageTransition"

const Layout = ({ children, location }) => {
    return (
        <>
            <SVGFilters />
            <Grid templateRows="auto 1fr" minH="100vh">
                <Header />
                <main>
                    <AnimateSharedLayout type="crossfade">
                        <AnimatePresence exitBeforeEnter>
                            <PageTransition
                                key={location.pathname}
                                currentPath={location.pathname}
                                prevPath={location?.state?.prevPath}
                            >
                                {children}
                            </PageTransition>
                        </AnimatePresence>
                    </AnimateSharedLayout>
                </main>
            </Grid>
        </>
    )
}

export default Layout
