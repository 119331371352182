import React, { PropsWithChildren } from "react"
import { Box, Text } from "@chakra-ui/react"

type FigureProps = {
    caption: ChildNode
}

function Figure({ children, caption }: PropsWithChildren<FigureProps>) {
    return (
        <Box as="figure" mb={2} w="full" maxW="calc(100vw - 3.1rem)" sx={{
            marginInline: 'auto !important',
        }}>
            {children}
            {caption && (
                <Text
                    as="figcaption"
                    my={2}
                    fontSize="md"
                    textAlign="center"
                    color="gray.400"
                    _dark={{
                        color: "gray.500",
                    }}
                >
                    {caption}
                </Text>
            )}
        </Box>
    )
}

export { Figure }
