import { Text, BoxProps } from "@chakra-ui/react";
import React from "react";
import { MotionBox } from "./MotionBox";

type ThumbnailBadgeProps = {
    id: string
} & BoxProps

function ThumbnailBadge({
    id,
    children,
    ...props
}: React.PropsWithChildren<ThumbnailBadgeProps>) {
    return (
        <MotionBox
            layoutId={id}
            position="absolute"
            top={0}
            right={0}
            bg="blackAlpha.700"
            zIndex={5}
            p={[2, null, 3]}
            borderEndStartRadius="md"
            textAlign="right"
            {...props}
        >
            <Text color="white" fontSize={["xx-small", null, "xs"]}>
                {children}
            </Text>
        </MotionBox>
    )
}

export { ThumbnailBadge }