import React from 'react'
import {Link as BaseLink, LinkProps as BaseLinkProps, LinkOverlay as BaseLinkOverlay, LinkOverlayProps as BaseLinkOverlayProps} from '@chakra-ui/react'
import {Link as GatsbyLink, GatsbyLinkProps} from 'gatsby'
import {useLocation} from '@reach/router'

type EnhancedLinkProps = GatsbyLinkProps<any> & BaseLinkProps;

function EnhancedLink({state = {}, ...props}: EnhancedLinkProps) {
    const location = useLocation()
    return <BaseLink as={GatsbyLink} state={{...state, prevPath: location.pathname}} {...props}/>
}

type EnhancedLinkOverlayProps = GatsbyLinkProps<any> & BaseLinkOverlayProps

function EnhancedLinkOverlay({state = {}, ...props}: EnhancedLinkOverlayProps) {
    const location = useLocation()
    return (
        <BaseLinkOverlay
            as={GatsbyLink}
            state={{ ...state, prevPath: location.pathname }}
            {...props}
        />
    )
}



export { EnhancedLink, EnhancedLinkOverlay }