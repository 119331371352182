import { keyframes } from '@emotion/react'

const slide = keyframes`
    0%{
        background-position-x:0%;
    }
    100% {
        background-position-x:100%;
    }
`

const baseStyle = {
    transition: 'outline none'
}

const variants = {
    default: {},
    underlined: {
        whiteSpace: {md: 'nowrap'},
        textDecoration: {base: 'underline', md: 'none'},
        position: 'relative',
        color: {base: 'blue.400', md: "inherit"},
        transitionProperties: 'common',
        transitionDuration: 'fast',
        transitionTimingFunction: 'linear',
        ':before': {
            '--strip-primary-color': 'colors.blue.300',
            '--strip-secondary-color': 'colors.blue.300',
            content: {md: '""'},
            position: 'absolute',
            height: 1,
            bottom: '-1.5',
            left: 0,
            right: 0,
            background: 'repeating-linear-gradient(45deg, var(--strip-primary-color), var(--strip-primary-color) 10px, var(--strip-secondary-color) 10px, var(--strip-secondary-color) 20px)',
            backgroundSize: "24px 24px",
            transform: 'scaleY(0.6)',
            transition: 'inherit',
            transformOrigin: 'bottom',
            animationDuration: 'calc(var(--text-length, 10) * 0.08s)'
        },
        '&:hover, &:focus-visible': {
            color: 'blue.500',
            textDecoration: {base: 'underline', md: 'none'},
            ':before': {
                '--strip-secondary-color': 'colors.blue.500',
                transform: 'scaleY(1)',
                animation: `${slide} infinite linear forwards`,
                animationDuration: 'calc(var(--text-length, 10) * 0.05s)'
            }
        },
        _focusVisible: {
            boxShadow: 'none',
        }
    }
}

const defaultProps = {
    variant: 'default'
}

export default {
    baseStyle,
    variants,
    defaultProps
}