import { Box, usePrefersReducedMotion } from "@chakra-ui/react"
import React, { useState } from 'react'
import {Target} from 'framer-motion'
import { MotionBox } from '../atoms/MotionBox'
import { homePageTransition } from '../../pages';
import { blogPageTransition } from '../../pages/blog';

type PageTransitionVariant = {
    initial: Target
    active: Target
    exit?: Target
}

export type PageTransitionType =
    | PageTransitionVariant
    | ([string | RegExp, PageTransitionVariant] | PageTransitionVariant)[]

const DefaultVariant: PageTransitionVariant = {
    initial: {},
    active: {},
    exit: {}
}

const pageTransitions: Record<string, PageTransitionType> = {
    "/": homePageTransition,
    "/blog": blogPageTransition,
    DEFAULT: DefaultVariant,
} as const

function PageTransition({children, currentPath, prevPath}) {
    const [isTransitioning, setIsTransitioning] = useState(false)
    const prefersReducedMotion = usePrefersReducedMotion()

    return (
        <Box
            sx={{ perspective: "800px" }}
            h="full"
            overflow={isTransitioning ? "hidden" : "auto"}
        >
            <MotionBox
                variants={
                    !prefersReducedMotion && getPageVariant(
                        pageTransitions,
                        currentPath,
                        prevPath
                    )
                }
                initial={prevPath ? "initial" : null}
                animate="active"
                exit="exit"
                transition={{ type: "spring", stiffness: 50, mass: 1 }}
                h="full"
                onAnimationStart={() => setIsTransitioning(true)}
                onAnimationComplete={() => setIsTransitioning(false)}
            >
                {children}
            </MotionBox>
        </Box>
    )
}

function getPageVariant(
    pageTransitions: Record<string, PageTransitionType>,
    currentPath: string,
    prevPath: string
) {
    const currTransition = pageTransitions[currentPath]
    if (currTransition) {
        if (Array.isArray(currTransition)) {
            const validTransition = currTransition.find((transition) => {
                if(!Array.isArray(transition)) return transition
                const [prevPathMatch, variant] = transition
                if (typeof prevPathMatch === "string") {
                    if (prevPathMatch === prevPath) return variant
                } else {
                    if (prevPathMatch.test(prevPath)) return variant
                }
            })
            if(validTransition) return validTransition
        } else if (typeof currTransition === "object") {
            return currTransition
        }
    }
    return DefaultVariant
}

export {PageTransition}