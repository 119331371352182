import { mode } from '@chakra-ui/theme-tools'

export default {
    global: props => ({
        '*,*::before,*::after': {
            outline: 'none',
            borderColor: 'transparent'
        },
        html: {
            transition: 'scrollbar-color 0.3s ease-out',
            'scrollbar-color': mode('var(--chakra-colors-blue-100) var(--chakra-colors-blue-300)', 'var(--chakra-colors-blue-700) var(--chakra-colors-gray-800)')(props),
            'scrollbar-width': 'thin',
        },
        body: {
            fontSize: 'lg',
            minH: '100vh',
            bg: mode('gray.50', 'gray.900')(props),
            color: mode('gray.800', 'gray.100')(props),
        },
        'body::-webkit-scrollbar-track': {
            backgroundColor:  mode('blue.100', 'gray.800')(props),
        },
        'body::-webkit-scrollbar': {
            backgroundColor:  mode('blue.100', 'gray.800')(props),
        },
        'body::-webkit-scrollbar-thumb': {
            border: '5px solid transparent',
            borderRadius: '100px',
            backgroundColor:  mode('blue.300', 'blue.700')(props),
            backgroundClip: 'content-box',
        },
        'body::-webkit-scrollbar-thumb:hover': {
            border: '5px solid transparent',
            borderRadius: '100px',
            backgroundColor:  mode('blue.400', 'blue.600')(props),
            backgroundClip: 'content-box',
        },
        '.grvsc-container': {
            maxWidth: 'calc(100vw - 3.1rem)',
            backgroundColor: 'var(--chakra-colors-blue-900) !important',
        },
        '.codecourse-contrast-rainglow': {
            borderRadius: 'md'
        },
        'code.codecourse-contrast-rainglow': {
            backgroundColor: 'var(--chakra-colors-blue-900) !important',
        },
        '@media (prefers-reduced-motion: no-preference)': {
            html: {
                scrollBehavior: 'smooth',
            }
        }
    }),
}
