import {
    Box,
    HStack, Text, usePrefersReducedMotion
} from "@chakra-ui/react"
import { Match } from "@reach/router"
import useScrollPosition from "@react-hook/window-scroll"
import { AnimatePresence, AnimateSharedLayout } from "framer-motion"
import React from "react"
import { ColorModeToggle } from "../atoms/ColorModeToggle/ColorModeToggle"
import { EnhancedLink } from "../atoms/EnhancedLink"
import { MotionBox } from "../atoms/MotionBox"

export default function Header() {
    const scrollY = useScrollPosition()
    const isWindowScrolled = scrollY > 0
    return (
        <Box
            as="header"
            position="sticky"
            top="0"
            left="0"
            right="0"
            bg="gray.50"
            _dark={{
                bg: "gray.900",
            }}
            sx={{
                "@supports (backdrop-filter: saturate(1))": {
                    bg: isWindowScrolled ? "whiteAlpha.800" : "gray.50",
                    backdropFilter: isWindowScrolled
                        ? "saturate(1.5) blur(15px)"
                        : "none",
                    _dark: {
                        bg: isWindowScrolled ? "blackAlpha.700" : "gray.900",
                        backdropFilter: isWindowScrolled
                            ? "saturate(2) blur(18px)"
                            : "none",
                    },
                },
            }}
            zIndex={5}
            transitionProperty="common"
            transitionDuration="normal"
            shadow={isWindowScrolled ? "md" : "none"}
        >
            <HStack
                spacing={10}
                justify="flex-end"
                mx="auto"
                maxWidth="container.lg"
                py={5}
                px={8}
            >
                <NavigationBar />
                <ColorModeToggle />
            </HStack>
        </Box>
    )
}

const navBarLinks = [
    {
        to: "/",
        title: "Home",
    },
    {
        to: "/blog",
        title: "Blog",
    },
]

function NavigationBar() {
    return (
        <nav>
            <AnimateSharedLayout>
                <HStack as="ul" spacing={6} alignItems="center" h="full">
                    {navBarLinks.map(({ to, title }) => (
                        <Box as="li" display="inline-block" key={to}>
                            <NavBarItem to={to} title={title} />
                        </Box>
                    ))}
                </HStack>
            </AnimateSharedLayout>
        </nav>
    )
}

function NavBarItem({ to, title }) {
    const prefersReducedMotion = usePrefersReducedMotion()
    return (
        <Match path={to}>
            {({ match }) => (
                <EnhancedLink
                    to={to}
                    position="relative"
                    _hover={{
                        color: "blue.500",
                        textDecoration: match ? "none" : "underline",
                    }}
                    _activeLink={{
                        color: "blue.600",
                    }}
                    _focus={{
                        boxShadow: "none",
                        color: "blue.600",
                    }}
                    _dark={{
                        _hover: {
                            color: "blue.200",
                        },
                        _activeLink: {
                            color: "blue.300",
                        },
                    }}
                >
                    <Text as="span" fontSize="xl">
                        {title}
                    </Text>
                    <AnimatePresence>
                        {match && (
                            <MotionBox
                                layoutId={
                                    !prefersReducedMotion &&
                                    "nav-outline-underline"
                                }
                                position="absolute"
                                bottom="-0.5em"
                                w="full"
                                h={1}
                                bg="blue.100"
                            />
                        )}
                    </AnimatePresence>
                </EnhancedLink>
            )}
        </Match>
    )
}
