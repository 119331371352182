export default {
    fonts: {
        body: "'Work Sans', sans-serif",
        heading: "'Work Sans', sans-serif",
    },
    fontSizes: {
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '4rem',
        lg: '1.125rem',
        md: '1rem',
        sm: '0.875rem',
        xl: '1.25rem',
        xs: '0.75rem',
    },
}
