import React from 'react'

export default function SVGFilters() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
            <filter id="filter-shadow-sm" width="5" height="5">
                <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation="5"
                    result="SHADOW"
                />
                <feMerge>
                    <feMergeNode in="SHADOW" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter id="filter-shadow-md" width="10" height="10">
                <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation="10"
                    result="SHADOW"
                />
                <feMerge>
                    <feMergeNode in="SHADOW" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter id="filter-shadow-lg" width="15" height="15">
                <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation="15"
                    result="SHADOW"
                />
                <feMerge>
                    <feMergeNode in="SHADOW" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <filter id="filter-shadow-xl" width="20" height="20">
                <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation="20"
                    result="SHADOW"
                />
                <feMerge>
                    <feMergeNode in="SHADOW" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </svg>
    )
}
