import Heading from './Heading'
import Code from './Code'
import Link from './Link'
import Input from './Input'

export default {
    Heading,
    Code,
    Link,
    Input
}
