import { Box } from '@chakra-ui/react'
import React from 'react'

const AudioPlayer = ({inline, ...props}) => {
    return (
        <Box
            as="audio"
            controls
            controlsList="nodownload"
            display={inline && "inline-block"}
            height={inline && "1.5em"}
            margin="0 auto"
            sx={{
                "::-webkit-media-controls-panel": {
                    background: "blue.200",
                },
            }}
            _dark={{
                "::-webkit-media-controls-panel": {
                    background: "blue.300",
                },
            }}
            {...props}
        />
    )
}

export { AudioPlayer }