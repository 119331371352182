import { Link } from '@chakra-ui/react'
import React from 'react'

function HeaderAnchorLink(props) {
    return (
        <Link
            display={['none', null, null, 'block']}
            position="absolute"
            top="50%"
            left="-1.1em"
            transform={`translateY(-50%)`}
            color="gray.800"
            opacity={0}
            sx={{
                "*:hover > &, &:hover, &:focus": {
                    opacity: "0.75",
                    color: "currentColor",
                },
            }}
            transitionProperty="common"
            transitionDuration="fast"
            transitionDelay="0.25s"
            {...props}
        />
    )
}

export {HeaderAnchorLink}