import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import theme from '../../theme'
import 'typeface-work-sans'
import MdxProviders from './MdxProviders'

const queryClient = new QueryClient()

const Root: React.FC = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme} resetCSS>
                <MdxProviders>{children}</MdxProviders>
            </ChakraProvider>
        </QueryClientProvider>
    )
}

export default Root
