import React from 'react'
import { Heading, Center, Stack } from '@chakra-ui/react'

import SocialLinks from '../components/compounds/SocialLinks'
import SEO from '../components/seo'
import { PageTransitionType } from '../components/wrappers/PageTransition'

const IndexPage = () => {
    return (
        <>
            <SEO title="Hazem Osama" />
            <Center minHeight="full">
                <Stack align="center" spacing={[6, null, 10]}>
                    <Heading
                        transitionProperty="color"
                        transitionDuration="normal"
                        as="h1"
                        fontSize={{ base: '20vw', md: '8vw' }}
                        lineHeight="none"
                        userSelect="none"
                    >
                        HAZEM <br /> OSAMA
                    </Heading>
                    <SocialLinks />
                </Stack>
            </Center>
        </>
    )
}

export const homePageTransition: PageTransitionType = {
    initial: {
        y: "5%",
        opacity: 0,
    },
    active: {
        y: 0,
        opacity: 1,
    },
    exit: {
        y: "5%",
        opacity: 0,
    },
}

export default IndexPage
